import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "../index.css";
import axios from "axios";

function Hero(props) {
  const [details, setDetails] = useState();
  const workStyle={
    textDecoration: "none"
  }

  async function fetchData() {
    try {
      const result = await axios.get(
        "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
      );
      if(result.data.country_name==="Not found" || result.data.city==="Not found"){
        setDetails(null);
      }
      else{
        setDetails(result.data);
      }
      
      // console.log(result.data);
    } catch (error) {}
  }

  const _details=useMemo(()=>details,[details])

  useEffect(() => {
    if(_details!==undefined){
      return 
    }
    fetchData();

  });

  return (
    <>
      <div id="home" className="hero-wrap">
        <div className="hero">
          <div className="hero-content-wrap">
            <div className="hero-content">
            {!details && (
                <h3>
                  Are you trying to establish an online brand?
                </h3>
              )}
              {details && (
                <h3>
                  Are you trying to establish an online brand in {details.city}
                  {details.city && <>,</>} {details.country_name}?
                </h3>
              )}
              <h1>Anil helps you to build a website that reflects your brand and increases sales.</h1>
              {/* <h1>Designs website for cause</h1> */}
              <Link style={workStyle} to="/contact">
              <div className="hero-span">
                <p>Yes, I want to work with Anil</p>
              </div>
              </Link>
            </div>
          </div>
          <div className="hero-image-wrap">
            <img src="myphoto.png" alt="Anil K. Panta"></img>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
