const Development=[
    {
        id: 1,
        image: "https://i.ytimg.com/vi/XeN8fIfylZs/maxresdefault.jpg",
        title: "GitHub Hosting",
        description: "Learn how to host using github for free",
        link: "https://youtu.be/XeN8fIfylZs"
    },
    {
        id: 2,
        image: "https://user-images.githubusercontent.com/35441380/123502667-dba90a80-d66d-11eb-93a6-d3c3f8c346b1.png",
        title: "Smart Home",
        description: "IoT Based Smart Home Using Face Recognization Security",
        link: "https://youtu.be/4j9fsOxirIg"
    }
]
export default Development;