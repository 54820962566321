import React, { useState } from "react";
import "../index.css";
import { Link } from 'react-router-dom'

function Service(props) {
  const [service1, setService1] = useState(true);
  const [service2, setService2] = useState(false);

  const vierallStyle={
    color: "black",
    textDecoration: "none"
  }

  const uiDesign = () => {
    setService1(true);
    setService2(false);
    document.querySelector(".service-react").style.background = "#4caf50";
    document.querySelector(".service-react").style.color = "white";
    document.querySelector(".service-ui").style.background = "#e5e5e5";
    document.querySelector(".service-ui").style.color = "black";
  };
  const development = () => {
    setService1(false);
    setService2(true);
    document.querySelector(".service-ui").style.background = "#4caf50";
    document.querySelector(".service-ui").style.color = "white";
    document.querySelector(".service-react").style.background = "#e5e5e5";
    document.querySelector(".service-react").style.color = "black";
  };

  return (
    <>
      <div id="service" className="service-wrap">
        <div className="service-title-wrap">
          <div className="service-title">
            <h1>Service</h1>
            <div className="service-btn-wrap">
              <div className="service-react" onClick={() => uiDesign()}>
                <p>UI/UX Design</p>
              </div>
              <div className="service-ui" onClick={() => development()}>
                <p>Development</p>
              </div>
            </div>
            <div className="service-viewall-wrap">
            <Link style={vierallStyle} to="/service">
                <div className="service-viewall">
                  <p>View All</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {service1 && (
          <div className="service">
            <div className="service-content-wrap">
              <div className="service-content">
                <div className="service-images">
                  <div className="image-1">
                    <img
                      src="https://files.muzli.space/bebb596c0a5ade83e1cbd2bff5bf40ea.jpeg"
                      alt="ui design"
                    ></img>
                    <div className="image-2">
                      <img
                        src="https://i.pinimg.com/originals/63/a7/b1/63a7b1894b3210d07e434e9d12170586.png"
                        alt="ui design"
                      ></img>
                    </div>
                    <div className="image-3">
                      <img
                        src="https://avatars.githubusercontent.com/u/35441380?v=4"
                        alt="ui design"
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="service-image-content">
                  <h3>#UI/UX Design</h3>
                  <p>
                  Anil is a designer focused on creating user-centric experiences and meaningful web applications. UI/UX Design involves multiple stages:
                    <ul>
                      <li>Research</li>
                      <li>Wireframing</li>
                      <li>UI Design</li>
                    </ul>
                    {/* UI (User Interface) and UX (User Experience) are basically
                    the initial research and the prototype design. It is done on
                    a paper and and after the final skeletan of the design the
                    software is designed with the UI designing tools like Figma
                    or Adobe XD. I prefer Figma for UI Design. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {service2 && (
          <div className="service">
            <div className="service-content-wrap">
              <div className="service-content">
                <div className="service-images">
                  <div className="image-1">
                    <img
                      src="https://nb6gx25bg013q32uq3y8z2h7-wpengine.netdna-ssl.com/wp-content/uploads/2020/01/Development-Frameworks-846x697.jpg"
                      alt="web development"
                    ></img>
                    <div className="image-2">
                      <img
                        src="https://images.ctfassets.net/51xdmtqw3t2p/2w0H06U9MYaJNsonXhyD3I/0cd72a4b4e01460bcd7145e984b05c38/Portada_react.jpg?w=1280&q=50"
                        alt="web development"
                      ></img>
                    </div>
                    <div className="image-3">
                      <img
                        src="https://avatars.githubusercontent.com/u/35441380?v=4"
                        alt="web development"
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="service-image-content">
                  <h3>#Web Development</h3>
                  <p>
                  Anil creates beautiful and functional websites for small businesses and rich interactive web apps. You can contact him for your React Js projects.
                    {/* After the UI Design it is sent for coding to give a digital
                    life. There are multiple technologies to develop a website
                    but I prefer React JS. React JS help develop a single page
                    application and increase user experience. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Service;
