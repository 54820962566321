import React from "react";
import { Link } from 'react-router-dom'

function ErrorPage(props) {
    const noneStyle={
        color: "black",
    textDecoration: "none"
    }
  return (
    <>
    <div className="errorpage-main">
      <div className="errorpage-wrap">
        <div className="error-profile-wrap">
          <div className="error-profile-online"></div>
          <img
            src="https://media-exp3.licdn.com/dms/image/C4D03AQHazZHYtEbx2g/profile-displayphoto-shrink_200_200/0/1623651901567?e=1631750400&v=beta&t=n0KB9EdEzaBeMGWwQ15t7fWRGlNuRGj7Rbj2hy3dyic"
            alt="anil k. error"
          ></img>
        </div>
        <div className="errorpage">
          <div className="error-404">
            <i class="far fa-window-close"></i>
            <p>Error 404</p>
          </div>
          <div className="errorpage-content">
            <h3>Page Not Available</h3>
            <h4>But Anil Is</h4>
          </div>
          <Link style={noneStyle} to="/">
          <div className="errorpage-goback">
            <i class="fas fa-chevron-left"></i>
            Back To Home Page
          </div>
          </Link>
        </div>
      </div>
      </div>
    </>
  );
}

export default ErrorPage;
