import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../index.css";

function ContactPage(props) {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    link: "",
    message: "",
  });
  const [clientName, setClientName] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [Name, setName] = useState("");

  let name, value;
  const contactValue = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { name, email, phone, link, message } = user;
    setName(name);

    if (name && email && message) {
      if (clientName || errorName) {
        setClientName(false);
        setErrorName(false);
      }
      setName(name);
      const res = await fetch(
        "https://portfolio-84852-default-rtdb.firebaseio.com/client_detail.json",
        {
          method: "POST",
          header: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phone,
            link,
            message,
          }),
        }
      );

      if (res) {
        setUser({
          name: "",
          email: "",
          phone: "",
          link: "",
          message: "",
        });
        setClientName(true);
      } else {
        setErrorName(true);
      }
    } else {
      setErrorName(true);
    }
  };
  const vierallStyle = {
    color: "black",
    textDecoration: "none",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={clientName ? "getBack-data" : "stop-data"}>
        Thank You! {Name} <br></br>I will get back to you soon
      </div>
      <div className={errorName ? "getBack-error-data" : "stop-data"}>
        Sorry! {Name} <br></br>
        Please fill up all the required field
      </div>
      <div className="contactpage-wrap">
        <div className="contactpage">
          <div className="contactpage-navbar">
            <Link style={vierallStyle} to="/">
              <div className="servicepage-logo">
                <div className="servicepage-arrow">
                  <i class="fas fa-angle-double-left"></i>
                </div>

                <div className="servicepage-logo-home">Home</div>
              </div>
            </Link>
          </div>
          <div className="contactpage-welcome">
            <h3>
              Hi there, I am <span>Anil K. Panta</span>
            </h3>
            <p>Thank You for showing interest on my work</p>
          </div>
          <div className="contactpage-content-wrap">
            <div className="contactpage-image-wrap">
              <div className="contactpage-image-background">
                <div className="contactpage-actual-image">
                  <img src="myPhoto.png" alt="contact anil"></img>
                </div>
              </div>
              <div className="contactpage-social">
                <a
                  href="https://www.instagram.com/a.k_panta/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/anil-kumar-panta/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="mailto: anilpanta2053@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="far fa-envelope-open"></i>
                </a>
              </div>
            </div>

            <div className="contactpage-image-content">
              <h1>Front End Developer</h1>
              <p>
                Available for: <span>Freelancing</span>{" "}
                <span>Contract Based Project</span>
              </p>
              <div className="contactpage-image-quotation">
                <div className="contentpage-quotation-left">
                  <i class="fas fa-quote-left"></i>
                </div>
                <p>
                  I am determined to turn your offline service online and help
                  to improve your branding. Once I work on your project, I will
                  make sure to provide my assistance for a lifetime.
                </p>
                <div className="contentpage-quotation-right">
                  <i class="fas fa-quote-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="contactpage-discuss-project-title">
            <h3>Do you want to discuss project with me?</h3>
            <p>
              It would be my pleasure to give a new digital perspective on your
              Idea with a well designed website.
            </p>
          </div>
          <div className="contactpage-form">
            <p>
              Please fill up all the field and mention the detailed query on the
              Query section.
            </p>
            <form method="POST">
              <div className="contactpage-form1">
                <input
                  type="text"
                  value={user.name}
                  onChange={contactValue}
                  name="name"
                  placeholder="Full Name"
                  required="yes"
                ></input>
                <input
                  type="text"
                  value={user.email}
                  onChange={contactValue}
                  name="email"
                  placeholder="Email Address"
                  required="yes"
                ></input>
                <input
                  type="number"
                  value={user.phone}
                  onChange={contactValue}
                  name="phone"
                  placeholder="Contact No."
                ></input>
                <input type="text" placeholder="Country, City"></input>
                <input
                  type="url"
                  value={user.link}
                  onChange={contactValue}
                  name="link"
                  placeholder="Social media link i.e Linkedin, Instagram"
                ></input>
              </div>
              <div className="contactpage-form2">
                <textarea
                  value={user.message}
                  onChange={contactValue}
                  name="message"
                  placeholder="Please mention in detail why do you want to contact me"
                  required="yes"
                ></textarea>
                <button onClick={postData}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
