import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Service from "../Components/Service";
import About from "../Components/About";
import Content from "../Components/Content";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";

function HomePage(props) {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Navbar />
      <Hero />
      <Service />
      <About />
      <Content />
      <Contact />
      <Footer />
    </>
  );
}

export default HomePage;
