import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import "../index.css";

function ServicePage(props) {
  const [servicepagebtn, setServicepagebtn] = useState(true);
  const [navbar1, setNavbar1] = useState(false);

  const vierallStyle = {
    color: "black",
    textDecoration: "none",
  };
  const workStyle={
    textDecoration: "none"
  }

  const changeNavbar = () => {
    if (window.scrollY > 60) {
      setNavbar1(true);
    } else {
      setNavbar1(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);

  const servicepageUI = () => {
    setServicepagebtn(true);
  };
  const servicepageDev = () => {
    setServicepagebtn(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="servicepage-wrap">
        <div
          className={
            navbar1
              ? "servicepage-navbar-wrap navActive"
              : "servicepage-navbar-wrap"
          }
        >
          <div className="servicepage-navbar">
            <Link style={vierallStyle} to="/">
              <div className="servicepage-logo">
                <div className="servicepage-arrow">
                  <i class="fas fa-angle-double-left"></i>
                </div>

                <div className="servicepage-logo-home">Home</div>
              </div>
            </Link>
            <div className="servicepage-title-wrap">
              <div
                className={
                  servicepagebtn ? "servicepage-ui activebtn" : "servicepage-ui"
                }
                onClick={servicepageUI}
              >
                UI/UX design
              </div>
              <div
                className={
                  servicepagebtn
                    ? "servicepage-development"
                    : "servicepage-development activebtn"
                }
                onClick={servicepageDev}
              >
                Development
              </div>
            </div>
            <Link style={workStyle} to="/contact">
            <div className="servicepage-work">Work with Anil</div>
            <div className="servicepage-work-logo">
              <i class="fas fa-briefcase fa-2x"></i>
            </div>
            </Link>
          </div>
        </div>
        {servicepagebtn && (
          <div className="servicepage-content-wrap">
            <div className="servicepage-content">
              <h1>UI/UX Design Process</h1>
              <div className="servicepage-content-research">
                <div className="sevicepage-content-letter1">
                  <h1>Research</h1>
                  <p>
                    UX and UI Design involves some serious design before actual
                    design. This Process involves case studies, visualization,
                    and more paperwork. Research is the foundation to bring out
                    beautiful and easy-to-use software.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="https://qual360.com/wp-content/uploads/2020/08/Market-Research.jpg"
                    alt="anil k. research"
                  ></img>
                </div>
              </div>
              <div className="servicepage-content-wireframming">
                <div className="sevicepage-content-letter2">
                  <h1>Wireframing</h1>
                  <p>
                    Wireframing gets involved along with the research phase. It
                    helps to draw the actual skeleton and placement of the
                    interactions. The UI design is the beautified version of
                    wireframing.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="https://i.pinimg.com/originals/63/39/65/6339652593bad6654e8de59f8dbb7ff7.png"
                    alt="anil k. wireframming"
                  ></img>
                </div>
              </div>
              <div className="servicepage-content-research">
                <div className="sevicepage-content-letter1">
                  <h1>UI Design</h1>
                  <p>
                    UI design is the last step of the actual UI/UX design before
                    the first client pitch. After all those research and
                    wireframing, I open my designing tool (Figma) and start
                    designing.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="https://cdn.dribbble.com/users/427857/screenshots/14245837/unity-desktop-animation.png?compress=1&resize=400x300"
                    alt="anil k. uidesign"
                  ></img>
                </div>
              </div>
            </div>

            <div className="servicepage-projects">
              <div className="servicepage-projects-title">
                <h1>Projects</h1>
              </div>
              <div className="servicepage-projects-details">
                <div className="servicepage-project-details-card"></div>
                <div className="servicepage-project-details-card"></div>
                <div className="servicepage-project-details-card"></div>
                <div className="servicepage-project-details-card"></div>
              </div>
            </div>
          </div>
        )}
        {!servicepagebtn && (
          <div className="servicepage-content-wrap">
            <div className="servicepage-content">
              <h1>Web Development Process</h1>
              <div className="servicepage-content-research">
                <div className="sevicepage-content-letter1">
                  <h1>UI Development</h1>
                  <p>
                    Thus finalized UI design in Figma is developed by coding.
                    The developed UI is made sure it is fully responsive in all
                    possible devices.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="https://i.imgur.com/VcmZ4vg.gif"
                    alt="anil k. research"
                  ></img>
                </div>
              </div>
              <div className="servicepage-content-wireframming">
                <div className="sevicepage-content-letter2">
                  <h1>Database & API Design</h1>
                  <p>
                    As required, different required APIs are developed. The
                    database is designed as per the complexity of the project.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="http://sibeeshpassion.com/wp-content/uploads/2015/10/Why-Web-API.png"
                    alt="anil k. wireframming"
                  ></img>
                </div>
              </div>
              <div className="servicepage-content-research">
                <div className="sevicepage-content-letter1">
                  <h1>API Integration & Testing</h1>
                  <p>
                    Developed APIs are integrated with the website and made the
                    website ready to test and present. But the website is
                    continuously maintained and updated with the update in
                    technology to keep client's service perform best.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="LiveSite.png"
                    alt="anil k. uidesign"
                  ></img>
                </div>
              </div>
            </div>

            <div className="servicepage-projects">
              <div className="servicepage-projects-title">
                <h1>Projects</h1>
              </div>
              <div className="servicepage-projects-details">
                <div className="servicepage-project-details-card"></div>
                <div className="servicepage-project-details-card"></div>
                <div className="servicepage-project-details-card"></div>
                <div className="servicepage-project-details-card"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default ServicePage;
