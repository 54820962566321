import React, { useState } from "react";
import "../index.css";

function Contact(props) {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    link: "",
    message: "",
  });
  const [clientName, setClientName] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [Name, setName] = useState("")


  let name, value;
  const contactValue = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const {name, email, phone, link, message} = user;
    setName(name)

    if(name && email && message){
      if(clientName || errorName){
        setClientName(false);
        setErrorName(false)
      }
      setName(name)
      const res = await fetch(
        "https://portfolio-84852-default-rtdb.firebaseio.com/portfoliocontact.json",
        {
          method: "POST",
          header: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phone,
            link,
            message,
          }),
        }
      );
      
      if(res){
        setUser({
          name: "",
          email: "",
          phone: "",
          link: "",
          message: "",
        })
    setClientName(true);
      }else{
        setErrorName(true)
      }
    }else{
      setErrorName(true)

    }
  };
  return (
    <>
    <div className={clientName ? "getBack-data" : "stop-data"}>
      Thank You! {Name} <br></br>
      I will get back to you soon
    </div>
    <div className={errorName ? "getBack-error-data" : "stop-data"}>
      Sorry! {Name} <br></br>
      Please fill up all the required field
    </div>
      <div id="contact" className="contact-wrap">
        <div className="contact-title-wrap">
          <div className="contact-title">
            <h1>Contact</h1>
          </div>
          
        </div>
        <div className="contact-content-wrap">
          <div className="contact-content">
            <div className="contact-content-image">
              <img
                src="https://kumberland.com/wp-content/uploads/2021/05/Anil-Panta-e1621933374677-640x641.jpg"
                alt="Anil Panta contact"
              ></img>
              <div className="contact-content-image-social">
              {/* <p>DM</p> */}
              <a
                href="https://www.linkedin.com/in/anil-kumar-panta/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-linkedin fa-2x"></i>
              </a>
              <a
                href="https://www.instagram.com/a.k_panta/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-instagram-square fa-2x"></i>
              </a>
              <a
                href="https://twitter.com/anil_panta"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-twitter-square fa-2x"></i>
              </a>
              <a
                href="mailto: anilpanta2053@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fas fa-envelope-square fa-2x"></i>
              </a>
            </div>
            </div>
            <div className="contact-content-field">
              <form method="POST">
                <input
                  type="text"
                  value={user.name}
                  onChange={contactValue}
                  name="name"
                  placeholder="Full Name (Required)"
                  required="yes"
                ></input>
                <input
                  type="email"
                  value={user.email}
                  onChange={contactValue}
                  name="email"
                  placeholder="Email Address (Required)"
                  required="yes"
                ></input>
                <input
                  type="number"
                  value={user.phone}
                  onChange={contactValue}
                  name="phone"
                  placeholder="Contact Number"
                ></input>
                <input
                  type="url"
                  value={user.link}
                  onChange={contactValue}
                  name="link"
                  placeholder="Social Media URL"
                ></input>
                <textarea
                  value={user.message}
                  onChange={contactValue}
                  name="message"
                  placeholder="(Required) Reason to contact me.."
                  required="yes"
                ></textarea>
                <button onClick={postData}>Submit</button>
              </form>
            </div>
            <div className="contact-content-social">
              {/* <p>DM</p> */}
              <a
                href="https://www.linkedin.com/in/anil-kumar-panta/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-linkedin fa-2x"></i>
              </a>
              <a
                href="https://www.instagram.com/a.k_panta/"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-instagram-square fa-2x"></i>
              </a>
              <a
                href="https://twitter.com/anil_panta"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fab fa-twitter-square fa-2x"></i>
              </a>
              <a
                href="mailto: anilpanta2053@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fas fa-envelope-square fa-2x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default Contact;
