import React from 'react';

function Footer(props) {
    return (
        <>
        <div className="footer-wrap">
            <div className="footer">
                <div className="footer-privacy">
                    <h1>ANIL K. PANTA</h1>
                    <div className="footer-privacy-copyright">
                        <p>©2021, by Anil K. Panta</p>
                    </div>
                </div>
                <div className="footer-letsgo">
                    Let me add value to your business
                </div>
                <div className="footer-media">
                <a href="https://www.youtube.com/channel/UCTSUxyWiQVAr5UwJc1M_LEQ" target="_blank" rel="noreferrer"><i class="fab fa-youtube fa-2x"></i></a>
                <a href="https://twitter.com/anil_panta" target="_blank" rel="noreferrer"><i class="fab fa-twitter fa-2x"></i></a>
                <a href="mailto: anilpanta2053@gmail.com" target="_blank" rel="noreferrer"><i class="fas fa-envelope fa-2x"></i></a>

                </div>

            </div>
        </div>
            
        </>
    );
}

export default Footer;