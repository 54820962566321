import React from "react";
import "../index.css";

function About(props) {
  return (
    <>
      <div id="about" className="about-wrap">
        <div className="about-title">
          <h1>About</h1>
        </div>
        <div className="about-content-wrap">
          <div className="about-content">
            <div className="about-content-letter">
              <h3>Anil K. Panta</h3>
              <p>
                Anil is a frontend developer who has been working for multiple
                national and international clients. In terms of his professional
                history, he has more experience with organizational management,
                decision making, and project management. He takes every
                challenge as his new opportunity.
                {/* Experienced a business strategy and operation along with the
                Engineering degree. Love developing business and software from
                scratch with a sustainable moto.<br></br>I love to explore hilly
                and himalaya regions through tracking on my break. */}
              </p>
              <div className="about-content-scroll-wrap">
                <div className="about-content-scroll">
                  <div className="scroll-1 content1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div className="scroll-1">
                    <img
                      src="https://avatars.githubusercontent.com/u/35441380?v=4"
                      alt="images"
                    ></img>
                  </div>

                  <div className="scroll-1">
                    <img
                      src="https://avatars.githubusercontent.com/u/35441380?v=4"
                      alt="images"
                    ></img>
                  </div>
                  <div className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div id="child9" className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div id="child9" className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div id="child9" className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div id="child9" className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div id="child9" className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div id="child9" className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                  <div id="child9" className="scroll-1">
                    <img src="myphoto.png" alt="images"></img>
                  </div>
                </div>
                <div className="scroll-overlap"></div>
              </div>
            </div>
            <div className="about-content-image">
              <div className="service-images">
                <div className="image-1">
                  <img
                    src="https://files.muzli.space/bebb596c0a5ade83e1cbd2bff5bf40ea.jpeg"
                    alt="Anil Panta"
                  ></img>
                  <div className="image-2">
                    <img
                      src="https://i.pinimg.com/originals/63/a7/b1/63a7b1894b3210d07e434e9d12170586.png"
                      alt="ui design"
                    ></img>
                  </div>
                  <div className="image-3">
                    <img
                      src="https://avatars.githubusercontent.com/u/35441380?v=4"
                      alt="ui design"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
