import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ServicePage from "./Pages/ServicePage";
import ContentPage from "./Pages/ContentPage";
import HomePage from "./Pages/HomePage";
import ErrorPage from "./Pages/ErrorPage";
import ContactPage from "./Pages/ContactPage";

function App() {
  


  return (
    <Router>
    <div className="App">
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/service" exact component={ServicePage} />
        <Route path="/content" exact component={ContentPage} />
        <Route path="/contact" exact component={ContactPage} />
        <Route component={ErrorPage} />
        {/* <ContactPage /> */}
      {/* <HomePage />
      <ServicePage />
      <ContentPage /> */}
      </Switch>
    </div>
    </Router>
  );
}

export default App;
