const Design = [{
    id: 1,
    image: "https://i.ytimg.com/vi/yDMM6q_FBfA/maxresdefault.jpg",
    title: "Canva Introduction",
    description: "Overview of the Design, syllabus of the course",
    link: "https://youtu.be/yDMM6q_FBfA"
},
{
    id: 2,
    image: "https://i.ytimg.com/vi/eb2Gl9i2xPs/maxresdefault.jpg",
    title: "Create Account",
    description: "Creating the free account in canva before getting started",
    link: "https://youtu.be/eb2Gl9i2xPs"
},
{
    id: 3,
    image: "https://i.ytimg.com/vi/UOPwAaNhotc/maxresdefault.jpg",
    title: "Template Editing",
    description: "Editing the predesigned templates in canva",
    link: "https://youtu.be/UOPwAaNhotc"
},
{
    id: 4,
    image: "https://i.ytimg.com/vi/kN-8nYBaBCg/maxresdefault.jpg",
    title: "Editing Background",
    description: "Using the available backgrounds in canva",
    link: "https://youtu.be/kN-8nYBaBCg"
},
{
    id: 5,
    image: "https://i.ytimg.com/vi/ee58W69jan0/maxresdefault.jpg",
    title: "Understanding Layers",
    description: "Working on layers in canva to create complex designs",
    link: "https://youtu.be/ee58W69jan0"
},
{
    id: 6,
    image: "https://i.ytimg.com/vi/4-D-zz4NVkI/maxresdefault.jpg",
    title: "Text and Fonts",
    description: "Best way to use texts and fonts in canva",
    link: "https://youtu.be/4-D-zz4NVkI"
},
{
    id: 7,
    image: "https://i.ytimg.com/vi/tRRRJfIIhW4/maxresdefault.jpg",
    title: "Text and Fonts more",
    description: "Best way to use texts and fonts in canva",
    link: "https://youtu.be/tRRRJfIIhW4"
},
{
    id: 8,
    image: "https://i.ytimg.com/vi/yGEeellIv5A/maxresdefault.jpg",
    title: "Grouping",
    description: "Understand the grouping concept in designing",
    link: "https://youtu.be/yGEeellIv5A"
},
{
    id: 9,
    image: "https://i.ytimg.com/vi/hYaY89d6NyI/maxresdefault.jpg",
    title: "Frames and Grid",
    description: "Using the prebuilt frames and grid of canva",
    link: "https://youtu.be/hYaY89d6NyI"
},
{
    id: 10,
    image: "https://i.ytimg.com/vi/D0c7IjW59P8/maxresdefault.jpg",
    title: "Gradient Concept",
    description: "Using gradient background to make more beautiful designs",
    link: "https://youtu.be/D0c7IjW59P8"
},
{
    id: 11,
    image: "https://i.ytimg.com/vi/Z22LBytN_V4/maxresdefault.jpg",
    title: "Editing Photo",
    description: "Editing photo in canva using layers and color management",
    link: "https://youtu.be/Z22LBytN_V4"
},
{
    id: 12,
    image: "https://i.ytimg.com/vi/DfpVh4mBTvQ/maxresdefault.jpg",
    title: "Banner Design",
    description: "Designing professional banners in canva",
    link: "https://youtu.be/DfpVh4mBTvQ"
},
{
    id: 13,
    image: "https://i.ytimg.com/vi/3VtGC6Wg6WM/maxresdefault.jpg",
    title: "Logo Design",
    description: "Designing costum logo and template logo",
    link: "https://youtu.be/3VtGC6Wg6WM"
},
{
    id: 14,
    image: "https://i.ytimg.com/vi/5YH2JndTqlE/maxresdefault.jpg",
    title: "Video Editing",
    description: "Using canva to edit video is not a great idea but it is fun",
    link: "https://youtu.be/5YH2JndTqlE"
},
]

export default Design;