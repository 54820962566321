import React, { useEffect, useState } from "react";
import "../index.css";
import { Link as Linkk } from 'react-scroll';
import { Link } from "react-router-dom";
import axios from "axios";

function Navbar(props) {
  
  const [navbar, setNavbar] = useState(false)
  const [catagory, setCatagory] = useState(false)
  const workStyle={
    textDecoration: "none"
  }
  const changeNavbar=()=>{
    if(window.scrollY>60){
      setNavbar(true)
    }
    else{
      setNavbar(false)
    }
  }
  window.addEventListener("scroll", changeNavbar);

  const openCatagory =()=>{
    setCatagory(true)
  }
  const closeCatagory =()=>{
    setCatagory(false)
  }

  const [code, setCode] = useState();

  async function fetchCode() {
    try {
      const result = await axios.get(
        "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
      );
      if(result.data.country_code==="Not found"){
        setCode(null);
      }
      else{
        setCode(result.data.country_code);
      }
      console.log(code)
      
      // console.log(result.data);
    } catch (error) {}
  }

  useEffect(() => {
    fetchCode();
    if(window.scrollY>60){
      setNavbar(true)
    }
    else{
      setNavbar(false)
    }
    
  }, []);

  return (
    <>
      <div className={navbar ? "navbar-wrap activeNav":"navbar-wrap"}>
        <div className="navbar">
        {/* <i class="fas fa-chevron-circle-down"></i> */}
        <i class={ catagory ? "passive" : "fas fa-bars"} onClick={openCatagory}></i>
        <i class={ catagory ? "fas fa-times" : "passive"} onClick={closeCatagory}></i>
        <div className={ catagory ? "catagory-small-wrap" : "passive"}>
          
          <p><Linkk to="service" spy={true} smooth={true}>Service</Linkk></p>
          <p><Linkk to="about" spy={true} smooth={true}>About</Linkk></p>
          <p><Linkk to="content" spy={true} smooth={true}>Content</Linkk></p>
          <p><Linkk to="contact" spy={true} smooth={true}>Contact</Linkk></p>
        </div>
          <div className="logo-wrap">
          <h3><Linkk to="home" smooth={true}>ANIL K.<sup>{code}</sup></Linkk></h3>
          </div>
          <div className="catagory-wrap">
          
            <p><Linkk to="service" spy={true} smooth={true}>Service</Linkk></p>
            <p><Linkk to="about" spy={true} smooth={true}>About</Linkk></p>
            <p><Linkk to="content" spy={true} smooth={true}>Content</Linkk></p>
            <p><Linkk to="contact" spy={true} smooth={true}>Contact</Linkk></p>
          </div>
          <Link style={workStyle} to="/contact">
          <div className="hire-wrap">
            <p>Work with Anil</p>
          </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Navbar;
