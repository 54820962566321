import React, { useState } from "react";
import "../index.css";
import Design from "../Api/Design";
import Development from "../Api/Development";
import { Link } from 'react-router-dom';
import Professional from "../Api/Professional";

function Content(props) {
  const [design, setDesign] = useState(true);
  const [development, setDevelopment] = useState(false);
  const [professional, setProfessional] = useState(false);
  
  const vierallStyle={
    color: "black",
    textDecoration: "none"
  }

  const designBtn = () => {
    setDesign(true);
    setDevelopment(false);
    setProfessional(false);
    document.querySelector(".title-type-design").style.background = "#4caf50";
    document.querySelector(".title-type-design").style.color = "white";
    document.querySelector(".title-type-development").style.background =
      "#e5e5e5";
    document.querySelector(".title-type-development").style.color = "black";
    document.querySelector(".title-type-professional").style.background =
      "#e5e5e5";
    document.querySelector(".title-type-professional").style.color = "black";
  };
  const developmentBtn = () => {
    setDesign(false);
    setDevelopment(true);
    setProfessional(false);
    document.querySelector(".title-type-development").style.background =
      "#4caf50";
    document.querySelector(".title-type-development").style.color = "white";
    document.querySelector(".title-type-design").style.background = "#e5e5e5";
    document.querySelector(".title-type-design").style.color = "black";
    document.querySelector(".title-type-professional").style.background =
      "#e5e5e5";
    document.querySelector(".title-type-professional").style.color = "black";
  };
  const professionalBtn = () => {
    setDesign(false);
    setDevelopment(false);
    setProfessional(true);
    document.querySelector(".title-type-professional").style.background =
      "#4caf50";
    document.querySelector(".title-type-professional").style.color = "white";
    document.querySelector(".title-type-design").style.background = "#e5e5e5";
    document.querySelector(".title-type-design").style.color = "black";
    document.querySelector(".title-type-development").style.background =
      "#e5e5e5";
    document.querySelector(".title-type-development").style.color = "black";
  };

  return (
    <>
      <div id="content" className="content-wrap">
        <div className="content-title-wrap">
          <div className="content-title">
            <h1>Content</h1>
            <div className="content-title-types">
              <div className="title-type-design" onClick={() => designBtn()}>
                Design
              </div>
              <div
                className="title-type-development"
                onClick={() => developmentBtn()}
              >
                Development
              </div>
              <div
                className="title-type-professional"
                onClick={() => professionalBtn()}
              >
                Professional
              </div>
            </div>
            <Link style={vierallStyle} to="/content">
            <div className="content-viewall">View All</div>
            </Link>
          </div>
        </div>
        <div className="content-data-wrap">
          <div className="content-data">
            {design && (
              <div className="content-data-design">
                {Design.map((curElem) => {
                  return (
                    // <>
                      <div className="design-data-card" key={curElem.id}>
                        <div className="card-image">
                          <img
                            src={curElem.image}
                            alt="canva introduction"
                          ></img>
                        </div>
                        <div className="card-letter-content">
                          <h1>{curElem.title}</h1>
                          <p>{curElem.description}</p>
                        </div>
                        <a href={curElem.link} target="_blank" rel="noreferrer">
                        <div className="card-button">Explore</div>
                        </a>
                      </div>
                    // </>
                  );
                })}
              </div>
            )}
            {development && (
              <div className="content-data-development">
                {Development.map((curElem) => {
                  return (
                    <>
                      <div className="development-data-card" key={curElem.id}>
                        <div className="card-image">
                          <img
                            src={curElem.image}
                            alt="canva introduction"
                          ></img>
                        </div>
                        <div className="card-letter-content">
                          <h1>{curElem.title}</h1>
                          <p>{curElem.description}</p>
                        </div>
                        <a href={curElem.link} target="_blank" rel="noreferrer">
                        <div className="card-button">Explore</div>
                        </a>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
            {professional && (
              <div className="content-data-professional">
                {Professional.map((curElem) => {
                  return (
                    <>
                      <div className="professional-data-card" key={curElem.id}>
                        <div className="card-image">
                          <img
                            src={curElem.image}
                            alt="canva introduction"
                          ></img>
                        </div>
                        <div className="card-letter-content">
                          <h1>{curElem.title}</h1>
                          <p>
                            {curElem.description}
                          </p>
                        </div>
                        <a href={curElem.link} target="_blank" rel="noreferrer">
                        <div className="card-button">Explore</div>
                        </a>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
