import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Design from "../Api/Design";
import Development from "../Api/Development";
import Professional from "../Api/Professional";
import Footer from "../Components/Footer";
import "../index.css";

function ContentPage(props) {
  const [contentbtn1, setContentbtn1] = useState({
    design: "design",
    development: "",
    professional: "",
  });
  const [navbar, setNavbar] = useState(false);

  const vierallStyle = {
    color: "black",
    textDecoration: "none",
  };
  const workStyle={
    textDecoration: "none"
  }

  const changeNavbar = () => {
    if (window.scrollY > 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);

  const contentDesign = () => {
    setContentbtn1({ design: "design" });
  };
  const contentDevelopment = () => {
    setContentbtn1({ development: "development" });
  };
  const contentProfessional = () => {
    setContentbtn1({ professional: "professional" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="servicepage-wrap">
        <div
          className={
            navbar
              ? "servicepage-navbar-wrap navActive"
              : "servicepage-navbar-wrap"
          }
        >
          <div className="servicepage-navbar">
            <Link style={vierallStyle} to="/">
              <div className="servicepage-logo">
                <div className="servicepage-arrow">
                  <i class="fas fa-angle-double-left"></i>
                </div>

                <div className="servicepage-logo-home">Home</div>
              </div>
            </Link>
            <div className="contentpage-title-wrap">
              <div
                className={
                  contentbtn1.design
                    ? "contentpage-ui activebtn"
                    : "contentpage-ui"
                }
                onClick={contentDesign}
              >
                Design
              </div>
              <div
                className={
                  contentbtn1.development
                    ? "servicepage-development activebtn"
                    : "servicepage-development"
                }
                onClick={contentDevelopment}
              >
                Development
              </div>
              <div
                className={
                  contentbtn1.professional
                    ? "servicepage-development activebtn"
                    : "servicepage-development"
                }
                onClick={contentProfessional}
              >
                Professional
              </div>
            </div>
            <Link style={workStyle} to="/contact">
            <div className="servicepage-work">Work with Anil</div>
            <div className="servicepage-work-logo">
              <i class="fas fa-briefcase fa-2x"></i>
            </div>
            </Link>
          </div>
        </div>
        {contentbtn1.design && (
          <div className="contentpage-content-wrap">
            <div className="servicepage-content">
              <div className="servicepage-content-research">
                <div className="sevicepage-content-letter1">
                  <h1>Design</h1>
                  <p>
                    Being a front-end developer, I need a decent idea of design.
                    Development is my primary focus, so I wanted to design
                    banners or graphics in less time using predesigned
                    templates. It leads me to know Canva, and I fell in love
                    with it. I didn't have to struggle with the concept of
                    design. Everything I needed was already there for me to use
                    for free. I request everyone interested in design or are a
                    beginner to it to learn Canva, as it will help to do things
                    fast and gives more concepts of design before getting
                    started with advanced software like photoshop.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="https://www.uxweb-design.com/wp-content/uploads/2019/10/Web-design-1.jpg"
                    alt="anil k. research"
                  ></img>
                </div>
              </div>
            </div>
            <div className="servicepage-projects">
              <div className="servicepage-projects-title">
                <h1>Tutorials</h1>
              </div>
              <div className="servicepage-projects-details">
                {Design.map((curElem) => {
                  return (
                    <div
                      className="servicepage-project-details-card"
                      key={curElem.id}
                    >
                      <img src={curElem.image} alt={curElem.title}></img>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {contentbtn1.development && (
          <div className="contentpage-content-wrap">
            <div className="servicepage-content">
              <div className="servicepage-content-research">
                <div className="sevicepage-content-letter1">
                  <h1>Development</h1>
                  <p>
                    Web Development is one of the highly demanded profiles all
                    around the world. On the surface, there are frontend and
                    backend development. But once we go with any of the ends,
                    there is too much stuff to learn and master. Learning
                    everything in a single go will demotivate new learners.
                    Everything has its phase of learning, and trust me it's too
                    much fun.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="https://cdn.kenzie.academy/wp-content/uploads/prod/2020/11/02155935/AdobeStock_241083104-1400x934.jpg"
                    alt="anil k. research"
                  ></img>
                </div>
              </div>
            </div>
            <div className="servicepage-projects">
              <div className="servicepage-projects-title">
                <h1>Tutorials</h1>
              </div>
              <div className="servicepage-projects-details">
                {Development.map((curElem) => {
                  return (
                    <div
                      className="servicepage-project-details-card"
                      key={curElem.id}
                    >
                      <img src={curElem.image} alt={curElem.title}></img>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {contentbtn1.professional && (
          <div className="contentpage-content-wrap">
            <div className="servicepage-content">
              <div className="servicepage-content-research">
                <div className="sevicepage-content-letter1">
                  <h1>Professional</h1>
                  <p>
                    Professionalism is a key to every profession. To be a
                    developer now needs a separate digital world where
                    people/clients try to approach you through your digital
                    activities and profile. To match with the same scenario, we
                    have to be more presentable on social media, emails, etc
                    which we use regularly. Improving digital presence helps a
                    lot in our professional life.
                  </p>
                </div>
                <div className="servicepage-content-image">
                  <img
                    src="https://assets.rebelmouse.io/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbWFnZSI6Imh0dHBzOi8vYXNzZXRzLnJibC5tcy8yNTk2NzI2MC9vcmlnaW4uanBnIiwiZXhwaXJlc19hdCI6MTYzNzM0NjgzNX0.mMx1HLcDnYuMLW2IRejjZEoXs4eHKKD_OPmQSjbzLYk/img.jpg?quality=80&width=744"
                    alt="anil k. research"
                  ></img>
                </div>
              </div>
            </div>
            <div className="servicepage-projects">
              <div className="servicepage-projects-title">
                <h1>Tutorials</h1>
              </div>
              <div className="servicepage-projects-details">
                {Professional.map((curElem) => {
                  return (
                    <div
                      className="servicepage-project-details-card"
                      key={curElem.id}
                    >
                      <img src={curElem.image} alt={curElem.title}></img>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default ContentPage;
